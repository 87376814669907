html .typo5 h1,
html .typo5 .tag-h1,
html .typo5 h2,
html .typo5 .tag-h2,
html .typo5 h3,
html .typo5 .tag-h3,
html .typo5 .body-copy-large {
  font-size: calc(var(--f-base-size) * 0.75);
}
html .typo5 h1.large,
html .typo5 .tag-h1.large {
  font-size: calc(var(--f-base-size) * 0.75);
}
@media (min-width: 1600px) {
  html .typo5 h4,
  html .typo5 .tag-h4 {
    font-size: 27px;
  }
}
